<template>
  <v-card :class="'d-flex flex-column mealCard ' + (meal && meal.isAlt ? 'mt-2' : '')" elevation="4" :loading="loading || saving">
    <v-dialog
      v-model="dialogRecipe"
      width="950"
      :fullscreen="$vuetify.display.smAndDown"
      scrollable
    >
      <recipe-details
        v-if="$vuetify.display.smAndUp"
        :key="recipe.id"
        :recipe="recipe"
        :title="recipe.name"
        :can-edit="false"
        :can-delete="false"
        @close="dialogRecipe = false"
        @update-score="(score)=>recipe.score = score"
      />
      <recipe-details-mobile
        v-if="$vuetify.display.xs"
        :key="recipe.id"
        :recipe="recipe"
        :title="recipe.name"
        :can-edit="false"
        :can-delete="false"
        @close="dialogRecipe = false"
        @update-score="(score)=>recipe.score = score"
      />
    </v-dialog>

    <v-dialog
      v-if="showScanner"
      v-model="showScanner"
      :fullscreen="$vuetify.display.smAndDown"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 bg-primary text-white">
          Scannez une carte
          <v-spacer v-if="$vuetify.display.smAndDown" />
          <span v-if="$vuetify.display.smAndDown">
            <v-btn
              icon
              @click="showScanner = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </span>
        </v-card-title>

        <v-card-text class="pa-0">
          <live-scanner
            :active-scan="showScanner"
            @select="selectFromScan"
          >
            <template #append-card="scope">
              <div class="scanCardButtonContainer">
                <v-btn
                  v-if="scope.item.type !== 'custom'"
                  icon
                  size="x-small"
                  variant="outlined"
                  class="ml-2 scanCardButton"
                  @click="selectFromScan(scope.item, true)"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  size="x-small"
                  variant="outlined"
                  class="ml-2 mt-2 scanCardButton"
                  @click="selectFromScan(scope.item, false)"
                >
                  <v-icon>
                    mdi-sync
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </live-scanner>
        </v-card-text>

        <v-card-actions v-if="$vuetify.display.mdAndUp">
          <v-spacer />
          <v-btn
            variant="text"
            @click="showScanner = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="ignoredDialog"
      v-model="ignoredDialog"
      :fullscreen="$vuetify.display.smAndDown"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 bg-primary text-white d-flex justify-space-between align-center">
          Rien à préparer
          <v-spacer v-if="$vuetify.display.smAndDown" />
          <span v-if="$vuetify.display.smAndDown">
            <v-btn
              icon
              variant="text"
              size="small"
              @click="resetIgnored()"
            >
              <v-icon size="x-large">
                mdi-close
              </v-icon>
            </v-btn>
            <v-btn
              v-if="leftoverIds.length > 0"
              icon
              variant="text"
              size="small"
              @click="setIgnored('leftover', leftoverIds)"
            >
              <v-icon size="x-large">
                mdi-check-circle
              </v-icon>
            </v-btn>
            <v-btn
              v-if="tmpIgnoredType === 'other'"
              icon
              variant="text"
              size="small"
              @click="setIgnored('other', undefined, comment)"
            >
              <v-icon size="x-large">
                mdi-check-circle
              </v-icon>
            </v-btn>
          </span>
        </v-card-title>

        <v-card-text class="pb-8">
          <v-row dense justify="center">
            <v-col v-if="tmpIgnoredType !== 'other'" cols="6" md="4">
              <v-card
                height="150"
                max-width="150"
                :disabled="lastMeals.length === 0"
                @click="tmpIgnoredType === '' ? tmpIgnoredType = 'leftover' : tmpIgnoredType = ''"
              >
                <v-img
                  :src="leftoverImg"
                  gradient="to top, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="150px"
                  cover
                  class="text-white text-center"
                >
                  <v-card-title>
                    Restes
                  </v-card-title>
                  <v-icon v-if="tmpIgnoredType === 'leftover'" size="x-large">
                    mdi-check-outline
                  </v-icon>
                </v-img>
              </v-card>
            </v-col>
            <v-col v-if="tmpIgnoredType !== 'leftover' && tmpIgnoredType !== 'other'" cols="6" md="4">
              <v-card
                height="150"
                max-width="150"
                @click="setIgnored('restaurant')"
              >
                <v-img
                  :src="restaurantImg"
                  gradient="to top, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="150px"
                  cover
                >
                  <v-card-title class="text-white text-center">
                    Restaurant
                  </v-card-title>
                </v-img>
              </v-card>
            </v-col>
            <v-col v-if="tmpIgnoredType === 'other'" cols="12" md="8">
              Commentaire :
              <v-textarea
                v-model="comment"
                variant="outlined"
                rows="2"
                hide-details
              />
            </v-col>
            <v-col v-if="tmpIgnoredType !== 'leftover'" cols="6" md="4">
              <v-card
                height="150"
                max-width="150"
                @click="tmpIgnoredType === '' ? tmpIgnoredType = 'other' : tmpIgnoredType = ''"
              >
                <v-img
                  :src="emptyImg"
                  gradient="to top, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="150px"
                  cover
                  class="text-white text-center"
                >
                  <v-card-title>
                    Autre
                  </v-card-title>
                  <v-icon v-if="tmpIgnoredType === 'other'" size="x-large">
                    mdi-check-outline
                  </v-icon>
                </v-img>
              </v-card>
            </v-col>
            <v-col v-if="tmpIgnoredType === 'leftover'" cols="12" md="8">
              On mange les restes de :
              <v-list
                class="lastMeals scrollbars"
                density="compact"
              >
                <v-list-item
                  v-for="item in lastMeals"
                  :key="item.id"
                >
                  <v-list-item-title
                    class="upperFirstLetter ellipsedTitle"
                    :title="formatMeal(item.id)"
                  >
                    {{ formatMeal(item.id) }}
                  </v-list-item-title>

                  <template #append>
                    <v-btn
                      v-if="!isIgnoredLeftover(item.id)"
                      icon
                      color="primary"
                      variant="flat"
                      size="small"
                      density="compact"
                      @click="addIgnoredLeftover(item.id)"
                    >
                      <v-icon>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="isIgnoredLeftover(item.id)"
                      icon
                      color="primary"
                      variant="outlined"
                      size="small"
                      density="compact"
                      @click="removeIgnoredLeftover(item.id)"
                    >
                      <v-icon>
                        mdi-minus
                      </v-icon>
                    </v-btn>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="$vuetify.display.mdAndUp">
          <v-spacer />
          <v-btn
            variant="text"
            @click="resetIgnored()"
          >
            Annuler
          </v-btn>
          <v-btn
            v-if="leftoverIds.length > 0"
            variant="outlined"
            @click="setIgnored('leftover', leftoverIds)"
          >
            Valider
          </v-btn>
          <v-btn
            v-if="tmpIgnoredType === 'other'"
            variant="outlined"
            @click="setIgnored('other', undefined, comment)"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text v-if="meal && meal.id" :class="'mealSummary flex-grow-1 pa-0 ' + (meal && meal.isAlt ? 'alt' : '')">
      <div v-if="meal && meal.isAlt" class="banner-container">
        <div class="banner">
          alt
        </div>
      </div>

      <div v-if="meal.ignored" class="content pa-3">
        <div class="ignored text-center">
          Rien à préparer
        </div>
        <div v-if="meal.ignored_type==='restaurant'" class="ignoredType text-center">
          Restaurant !
        </div>
        <div v-if="meal.ignored_type==='leftover'" class="ignoredType">
          Restes de :
          <ul class="pl-4">
            <li v-for="lid in meal.leftover_source_ids" :key="lid">
              {{ formatMeal(lid, 'Repas supprimé') }}
            </li>
          </ul>
        </div>
        <div v-if="meal.comment" class="description upperFirstLetter">
          {{ meal.comment }}
        </div>
      </div>

      <div v-if="!meal.ignored" class="content pb-2">
        <div class="name upperFirstLetter pa-3">
          {{ meal.name }}
        </div>
        <div v-if="meal.comment" class="description upperFirstLetter px-3">
          {{ meal.comment }}
        </div>
        <v-row v-if="meal && meal.id" class="information flex-wrap px-3 my-0" justify="space-around">
          <v-col cols="auto" class="py-1 px-1">
            <v-chip pill title="Nombre de personnes">
              <v-avatar start color="secondary">
                <v-icon>
                  mdi-account-group
                </v-icon>
              </v-avatar>
              {{ nb_people }}
            </v-chip>
          </v-col>

          <v-col cols="auto" class="py-1 px-1">
            <VDropdown
              v-if="meal.composition.length > 0"
              placement="top"
            >
              <v-chip
                pill
                title="Ingrédients"
              >
                <v-avatar start color="secondary">
                  <v-icon>
                    mdi-food-apple
                  </v-icon>
                </v-avatar>
                {{ meal.composition.length }}
              </v-chip>
              <template #popper>
                <v-list v-if="meal.composition" class="ingList scrollbars">
                  <v-list-item
                    v-for="compo in meal.composition"
                    :key="compo.id"
                    class="ingredientContainer"
                  >
                    <template #prepend>
                      <v-img
                        v-if="compo.ingredient && compo.ingredient.image_url"
                        :src="compo.ingredient.image_url"
                        height="24"
                        width="24"
                        class="ma-0 mr-2"
                      />
                    </template>
                    <v-list-item-title :class="!compo.ingredient || !compo.ingredient.image_url ? 'ml-6 pl-2' : ''">
                      {{ compo.ingredient ? compo.ingredient.name : '' }}
                      {{ formatQuantity(compo.amount, compo.unit_shorthand) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </template>
            </VDropdown>
            <v-chip v-if="meal.composition.length === 0" pill>
              <v-avatar start color="secondary">
                <v-icon>
                  mdi-food-apple
                </v-icon>
              </v-avatar>
              {{ meal.composition.length }}
            </v-chip>
          </v-col>

          <v-col v-if="meal.recipes.length > 0" cols="auto" class="py-1 px-1">
            <VDropdown
              placement="top"
            >
              <v-chip
                pill
                title="Recettes"
                :disabled="loadingRecipes"
                @click="toggleRecipes"
              >
                <v-avatar start color="secondary">
                  <v-icon>
                    mdi-pasta
                  </v-icon>
                </v-avatar>
                {{ meal.recipes.length }}
              </v-chip>
              <template #popper>
                <v-container v-if="meal.composition" class="pa-3 d-flex flex-column">
                  <v-btn
                    v-for="mealRecipe in mealRecipes"
                    :key="mealRecipe.id"
                    size="small"
                    class="mb-2"
                    variant="text"
                    :loading="loadingRecipe === mealRecipe.id"
                    @click="openRecipe(mealRecipe)"
                  >
                    {{ mealRecipe ? mealRecipe.name : '' }}
                    <v-icon size="small" class="ml-2">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </v-container>
              </template>
            </VDropdown>
          </v-col>
        </v-row>
      </div>

      <v-btn
        v-if="showAltBtn"
        color="primary"
        size="small"
        class="extraMealButton"
        title="Repas alternatif"
        @click="create(true)"
      >
        <v-icon size="small" class="mr-2">
          mdi-plus-thick
        </v-icon>
        Alt
      </v-btn>
    </v-card-text>
    <v-card-text v-if="!meal || !meal.id" class="mealSummary empty">
      <div class="emptyPlate" @click="create(false)">
        <span class="content">
          +
        </span>
      </div>

      <v-btn
        v-if="showAltBtn"
        color="primary"
        size="small"
        class="extraMealButton"
        title="Repas alternatif"
        @click="create(true)"
      >
        <v-icon size="small" class="mr-2">
          mdi-plus-thick
        </v-icon>
        Alt
      </v-btn>
    </v-card-text>
    <v-card-actions class="actions pa-0">
      <v-btn
        v-if="!meal || !meal.id"
        label=""
        density="compact"
        size="small"
        :disabled="isOffline"
        @click="ignoredDialog = true"
      >
        Rien à préparer
      </v-btn>
      <v-btn
        v-if="meal && meal.id"
        icon
        color="primary"
        title="Modifier"
        :disabled="isOffline"
        size="small"
        @click="edit"
      >
        <v-icon size="large">
          mdi-pencil
        </v-icon>
      </v-btn>

      <v-spacer />

      <v-btn
        v-if="meal && meal.id"
        icon
        color="primary"
        title="Déplacer"
        :disabled="isOffline"
        size="small"
        class="mx-0"
        @click="moveMeal(meal.id)"
      >
        <v-icon size="large">
          mdi-swap-horizontal-bold
        </v-icon>
      </v-btn>
      <v-btn
        v-if="meal && meal.id"
        icon
        color="primary"
        title="Supprimer"
        :disabled="isOffline"
        size="small"
        class="mx-0"
        @click="destroy(meal.id)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-menu v-if="meal || canScanCode === true">
        <template #activator="{ props }">
          <v-btn
            icon
            color="primary"
            title="Plus"
            size="small"
            class="mx-0"
            v-bind="props"
          >
            <v-icon size="large">
              mdi-dots-horizontal
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="canScanCode === true"
            :disabled="isOffline"
            @click="showScanner = true"
          >
            <template #prepend>
              <v-icon size="large" color="primary">
                mdi-qrcode-scan
              </v-icon>
            </template>
            Scanner
          </v-list-item>
          <reminder-button
            v-if="meal"
            :title="reminderInfo.title"
            :details="reminderInfo.details"
            :start="reminderInfo.start"
            :end="reminderInfo.end"
          />
        </v-list>
      </v-menu>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash"
import { useRepo } from 'pinia-orm'
import { Globalconfig } from "@/models/Globalconfig"
import { Meal, MealRepo } from "@/models/Meal"
import { Ingredient, IngredientRepo } from "@/models/Ingredient"
import { Recipe, RecipeRepo } from "@/models/Recipe"
import { RecipeIngredientRepo } from '@/models/RecipeIngredient'
import { RecipeMealRepo } from '@/models/RecipeMeal'
import PWA from "@/shared/pwa"
import ReminderButton from '@/components/ReminderButton'
import RecipeDetails from '@/components/RecipeDetails'
import RecipeDetailsMobile from '@/components/RecipeDetailsMobile'
import LiveScanner from '@/components/LiveScanner'
import moment from 'moment'
import QrScanner from 'qr-scanner'
import { hideAllPoppers } from 'floating-vue'
import restaurantImg from '@/assets/restaurant.jpg'
import leftoverImg from '@/assets/leftover.jpg'
import emptyImg from '@/assets/empty.jpg'

export default {
  name: "MealSummaryCard",
  components: {
    ReminderButton,
    RecipeDetails,
    RecipeDetailsMobile,
    LiveScanner
  },
  props: {
    mealId: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ""
    },
    date: {
      type: Date,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showAltBtn: {
      type: Boolean,
      default: false
    },
  },
  emits: ['delete', 'moveMeal', 'createalt', 'create', 'edit'],
  data: () => ({
    saving: false,
    searchingIngredients: false,
    mealRecipes: [],
    loadingRecipes: false,
    loadingRecipe: 0,

    dialogRecipe: false,
    recipe: {},

    canScanCode: false,
    showScanner: false,

    ignoredDialog: false,
    tmpIgnoredType: '',
    leftoverIds: [],
    leftoverImg,
    restaurantImg,
    emptyImg,
    comment: ''
  }),
  computed: {
    isOffline() {
      return useRepo(Globalconfig).find('isOffline').value
    },
    meal() {
      return useRepo(MealRepo).withAllRecursive().find(this.mealId)
    },
    lastMeals() {
      return useRepo(MealRepo)
        .withAllRecursive()
        .where('ignored', false)
        .where('date', (value) => moment.utc(value).isBetween(moment.utc(this.date).subtract(15, 'days'), this.date))
        .orderBy(m => moment.utc(m.date).unix(), 'desc')
        .get()
    },
    nb_people() {
      const dnp = useRepo(Globalconfig).find("default_nb_people")
      const def = dnp ? dnp.value : 0
      return this.meal && this.meal.nb_people ? this.meal.nb_people : def
    },
    isPwa() {
      return PWA.isPwa()
    },
    reminderInfo() {
      const strDate = moment(this.date).format('Y-MM-DD')
      const strName = this.meal && this.meal.name ? this.meal.name[0].toUpperCase() + this.meal.name.substring(1) : ''
      const start = moment(this.date).subtract(1, 'day').hours(19).minutes(0).seconds(0).toDate()
      return {
        title: 'Rappel pour '+strName,
        details: strName + "\n" + import.meta.env.VITE_SITE_URL+'/?date='+strDate,
        start: start,
        end: moment(start).add(1, 'hour').toDate()
      }
    }
  },
  mounted: async function () {
    this.checkCameraAvailability()
  },
  methods: {
    async checkCameraAvailability() {
      try {
        const hasCam = await QrScanner.hasCamera()
        this.canScanCode = hasCam
      }
      catch(e) {
        this.canScanCode = false
      }
    },
    async selectFromScan(item, addScan) {
      if(addScan) {
        if(item.type === 'ingredient') {
          if(!this.meal) {
            const meal = await useRepo(MealRepo).$create({
              name: useRepo(Ingredient).findByKey(item.id).name,
              date: this.date,
              type: this.type
            })
            const newMealId = meal.id
            await useRepo(MealRepo).$updateIngredients(newMealId)
          }
          else {
            await useRepo(MealRepo).$update(this.meal.id, {
              name: this.meal.name + ', ' + useRepo(Ingredient).findByKey(item.id).name
            })
            await useRepo(MealRepo).$updateIngredients(this.meal.id)
          }
        }
        else if(item.type === 'recipe') {
          if(!this.meal) {
            const meal = await useRepo(MealRepo).$create({
              name: useRepo(Recipe).find(item.id).name,
              date: this.date,
              type: this.type,
              recipes: [
                {
                  recipe_id: item.id
                }
              ]
            })
            const newMealId = meal.id
            await useRepo(RecipeMealRepo).$getByMeal(newMealId)
            await useRepo(MealRepo).$updateIngredients(newMealId)
          }
          else {
            await useRepo(MealRepo).$update(this.meal.id, {
              name: this.meal.name + ', ' + useRepo(Recipe).find(item.id).name,
              recipes: this.meal.recipes ? this.meal.recipes.concat([{recipe_id: item.id}]) : [{recipe_id: item.id}]
            })
            await useRepo(RecipeMealRepo).$getByMeal(this.meal.id)
            await useRepo(MealRepo).$updateIngredients(this.meal.id)
          }
        }
      }
      else {
        // Replacing so this is once: close scanner
        this.showScanner = false

        const date = this.meal ? this.meal.date : this.date
        const type = this.meal ? this.meal.type : this.type
        const dnp = userRepo(Globalconfig).find("default_nb_people")
        const nb_people =dnp ? dnp.value : 1
        if(this.meal) {
          await useRepo(Meal).$delete(this.meal.id)
        }
        if(item.type === 'ingredient') {
          const meal = await useRepo(MealRepo).$create({
            name: useRepo(Ingredient).findByKey(item.id).name,
            date: this.date,
            type: this.type
          })
          const newMealId = meal.id
          await useRepo(MealRepo).$updateIngredients(newMealId)
        }
        else if(item.type === 'recipe') {
          const meal = await useRepo(MealRepo).$create({
            name: useRepo(Recipe).find(item.id).name,
            date: this.date,
            type: this.type,
            recipes: [
              {
                recipe_id: item.id
              }
            ]
          })
          const newMealId = meal.id
          await useRepo(RecipeMealRepo).$getByMeal(newMealId)
          await useRepo(MealRepo).$updateIngredients(newMealId)
        }
        else if(item.type === 'custom') {
          await useRepo(MealRepo).$create({
            ignored: item.type === 'custom',
            comment: item.id === 'leftover' ? 'Restes' : (item.id === 'restaurant' ? 'Restaurant' : ''),
            date: this.date,
            type: this.type
          })
        }
      }
    },
    formatQuantity(amount, unit_label) {
      const qtt = Ingredient.formatQuantity(amount, unit_label)
      if (qtt) {
        return "(" + qtt + ")"
      } else {
        return ""
      }
    },
    startSaving() {
      this.saving = true
    },
    endSaving() {
      this.saving = false
    },
    destroy(mealId) {
      this.$emit('delete', mealId)
    },
    moveMeal (mealId) {
      this.$emit('moveMeal', mealId)
    },
    addIgnoredLeftover (mealId) {
      this.leftoverIds.push(mealId)
    },
    removeIgnoredLeftover (mealId) {
      this.leftoverIds.splice(this.leftoverIds.indexOf(mealId), 1)
    },
    isIgnoredLeftover (mealId) {
      return this.leftoverIds.indexOf(mealId) > -1
    },
    setIgnored: async function (ignored_type, leftover_source_ids, comment) {
      // Setting ignored can only come from an empty meal so no need to add more info
      await useRepo(MealRepo).$create({
        ignored: true,
        ignored_type,
        leftover_source_ids,
        date: this.date,
        type: this.type,
        comment
      })
      this.tmpIgnoredType = ''
      this.leftoverIds = []
    },
    updateIngredients: async function() {
      if(this.meal && this.meal.id) {
        this.searchingIngredients = true
        await useRepo(MealRepo).$updateIngredients(this.meal.id)
        this.searchingIngredients = false
      }
    },
    create: function(alt) {
      if(!this.isOffline) {
        if(alt) {
          this.$emit('createalt')
        }
        else {
          this.$emit('create')
        }
      }
    },
    edit: function() {
      this.$emit('edit', this.meal)
    },
    toggleRecipes: async function () {
      this.allowCloseRecipes = false
      if(this.showRecipes) {
        this.showRecipes = false
      }
      else {
        this.loadingRecipes = true
        const ids = this.meal.recipes.reduce((prev, curr) => {
          // Try to find it in store before querying
          const inStore = useRepo(Recipe).find(curr.recipe_id)
          if(!inStore) {
            prev.push(curr.recipe_id)
          }
          return prev
        }, [])
        if(ids.length > 0) {
          await useRepo(RecipeRepo).$fetch({
            ids: ids
          })
        }
        const recipes = useRepo(Recipe).find(this.meal.recipes.map(r => r.recipe_id))
        this.mealRecipes = recipes || []
        this.loadingRecipes = false
        this.showRecipes = true
      }
    },
    openRecipe: async function(recipe) {
      this.loadingRecipe = recipe.id
      await Promise.all([
        useRepo(IngredientRepo).$getByRecipe(recipe.id),
        useRepo(RecipeIngredientRepo).$getByRecipe(recipe.id)
      ])
      this.loadingRecipe = 0
      hideAllPoppers()
      this.recipe = useRepo(Recipe).withAllRecursive().find(recipe.id)
      this.dialogRecipe = true
    },
    formatDate(date) {
      return moment.utc(date).format('DD/MM/YY')
    },
    formatMeal(id, defaultValue) {
      const meal = useRepo(Meal).find(id)
      return meal ? meal.summary : (defaultValue ? defaultValue : '')
    },
    formatTitle(title) {
      return _.upperFirst(title)
    },
    resetIgnored () {
      this.tmpIgnoredType = ''
      this.ignoredDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mealCard {
  overflow: visible;
  max-width: 100%;
}
.mealSummary {
  min-height: 230px;
  height: 100%;
  display: flex;
  align-items: stretch;

  &.alt {
    min-height: 100px;
    margin-bottom: 30px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .name {
      flex-grow: 1;
      font-size: 2em;
      line-height: 1.2em;
      color: rgb(var(--v-theme-primary));
      padding-bottom: 10px;
    }

    .description {
      flex-grow: 0;
      flex-shrink: 0;
      margin-top: 12px;
    }

    .information {
      flex-grow: 0;

      display: flex;
      justify-content: space-around;
    }

    .ignored {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 1.5em;
      color: rgb(var(--v-theme-secondary));
    }
  }

  &.empty {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    justify-content: center;

    .emptyPlate {
      width: 100%;
      max-width: 200px;
      align-self: center;
      position: relative;

      .content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 150px;
        color: rgb(var(--v-theme-secondary));
        cursor: pointer;
      }

      &:before {
        content: "";
        width: 100%;
        padding-bottom: 100%;
        float: left;
        box-sizing: content-box;

        border-radius: 50%;
        box-shadow: 0 0 10px 0px rgba(0, 0, 0, .2) inset;
      }
    }
  }
}

.actions {
  border-top: 1px solid grey;
  gap: 0;
}

.ingredient {
  padding: 2px 2px 2px 6px;
  margin-right: 3px;
  display: inline-block;
}

.ingList {
  max-height: 250px;
  overflow: auto;
}

.extraMealButton {
  position: absolute;
  left: 50%;
  width: 80px;
  margin-left: -40px;
  bottom: -28px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.banner-container {
	width: 75px;
	height: 75px;
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
}

.banner {
	width: 100px;
	background-color: rgb(var(--v-theme-primary));
	color: #fff;
	text-align: center;
	transform: rotate(45deg) translate(9%, 0%);
	text-transform: uppercase;
}

.ingredientContainer {
  min-height: 28px;
}

.scanCardButtonContainer {
  display: inline-block;
}
.scanCardButton {
  display: block;
}

.lastMeals {
  max-height: 200px;
  overflow: auto;
}

.ellipsedTitle {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>